.MuiStepLabel-label.MuiStepLabel-active {
  color:#685DA8 !important;
  font-family: Graviola;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
}

.MuiStepLabel-label {
  color: #E0E0E0 !important;
  font-family: Graviola !important;
  font-style: normal;
  font-weight: normal !important;
  font-size: 20px !important;
  line-height: 25px !important;
}

.MuiStepLabel-label.MuiStepLabel-completed {
  color: #685DA8 !important;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #685DA8 !important;
}

.MuiStepIcon-root {
  color: #E0E0E0 !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #4ED25D !important;
}