.mealNewContainer {
	width: 100%;
	max-width: 1152px;
	margin-top: -32px;
}

.paperContainer {
	margin-top: 0px !important;
	justify-content: center;
	padding-top: 74px;
	padding-bottom: 40px;
}

.mealForm {
	.type-calories-preparationTime-difficult {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-right: 10px;
		margin-left: -10px;
		margin-top: 24px;
	}

	.categoriesOfMeals {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-right: -6px;
		margin-left: -18px;
		margin-top: 24px;
	}

	.description-additionalInformation {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		margin-top: 24px;

		.description {
			width: 49%;
			display: flex;
			flex-direction: column;
			margin-bottom: 24px;
		}

		.additionalInformation {
			width: 49%;
			display: flex;
			flex-direction: column;
			margin-bottom: 24px;
		}
	}

	.actionButtons {
			margin-top: 40px;
			margin-right: -10px;
			margin-left: -10px;
			margin-bottom: auto;
	}
}

.dialog {
	.MuiDialog-paper {
		overflow-x: hidden;
	}

	.listTile {
		border-width: 0.3px;
		border-color: #e0e0e0;
		border-style: solid;
		position: relative;
		cursor: pointer;

		:hover {
			background-color: rgba(104, 93, 168, 0.3);

			img {
				opacity: 0.7;
			}
		}
	}
}

.selectPictureButtonContainer {
	margin-left: -10px !important;
	margin-top: 24px !important;
}

.selectedPictureContainer {
	margin: 24px 0px !important;
}

.selectedPicture {
	width: 240px;
	height: 124px;
}