.paragraph {
  font-family: Graviola, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #737373;
  text-transform: none;
  width: max-content;
}

.paragraphSecondary {
  font-family: Graviola;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #B2B2B2;
  text-transform: none;
}

.paragraphPurple {
  font-family: Graviola, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #685DA8;
}

.paragraphWhite {
  font-family: Graviola, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  text-transform: none;
}

.title {
  font-family: Graviola, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  color: #685DA8;
}

.hintTitle {
  font-family: Graviola, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  color: #8998A4;
}

.appBackground{
  background-color: #FAFAFA;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.description {
  font-family: Graviola, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #b3b3b3;
}

.descriptionPurple {
  font-family: Graviola, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #685DA8;
}

.descriptionWhite {
  font-family: Graviola, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
}

.h1Style {
  font-family: Graviola, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 60px;
  text-transform: uppercase;
  color: #737373;
}

.h2Style {
  font-family: Graviola, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 42px;
  line-height: 52px;
  color: #737373;
}

.h3Style {
  font-family: GraviolaSoft, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 37px;
  color: #737373;
}

.h4Style {
  font-family: Graviola, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  text-transform: "uppercase";
  color: #737373;
}

.h4NormalStyle {
  font-family: Graviola, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  color: #737373;
}

.h5Style {
  font-family: Graviola, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  color: #737373;
}

.h5NormalStyle {
  font-family: Graviola, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  color: #737373;
}

.h5Purple {
  font-family: Graviola, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  color: #685DA8;
}

.h6Style {
  font-family: Graviola, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #737373;
}

.h6StylePurple {
  font-family: Graviola, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #685DA8;
}

.h6NormalStyle {
  font-family: Graviola;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #737373;
}

.h6NormalPurpleStyle {
  font-family: Graviola;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #685DA8;
}

.descriptionContrast {
  font-family: Graviola, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #D64E0F;
}

.reloadButton {
  font-family: Graviola, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: white;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 4px;
  padding: 4px 6px;
}

.rdw-editor-wrapper {
  margin-top: 30px;
}

.MuiFormLabel-root {
  font-family: 'Graviola' !important;
}

.MuiInputBase-input, .MuiListItem-root {
  font-family: 'Graviola' !important;
  font-size: 14px !important;
  color: #737373 !important;
  mix-blend-mode: normal !important;
}

.MuiButton-label {
  font-family: 'GraviolaSoft' !important;
  font-size: 16px !important;
}

.clickableText {
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  color: #685da8;
  cursor: pointer;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}