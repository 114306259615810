@import '../../assets//styles/Style';

.sortLabel {
  @extend %h6NormalStyle;

  color: #737373 !important;
}

.selectedColumn {
  path {
    fill: #F57A3D;
  }
}