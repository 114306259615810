
.cardContainer{ 
  display: flex;
  flex-direction: column;
  padding: 30px;
  min-height: 400px;
  max-height: 400px;
  position: relative;
}

.spaceLeftTitle {
  margin-left: 10px;
}

.imgCard {
  height: 32;
}

.titleCardContainer {
  align-items: center;
  margin-bottom: 30px;
  display: flex;
}

.spaceCardSubtitle {
  display: flex;
  width: 100%;
  max-height: 100%;
  justify-content: space-between !important;
  align-items: flex-end !important;
  margin-bottom: 15px;
}

.spaceCardSubtitleWithoutMargin {
  display: flex;
  width: 100%;
  max-height: 100%;
  justify-content: space-between !important;
  align-items: flex-end !important;
}

.spaceCardSubtitleMoreLife {
  display: flex;
  width: 100%;
  max-height: 100%;
  justify-content: space-between !important;
  align-items: flex-end !important;
  margin-bottom: 5px;
}

.spaceCardSubtitleMoreLifeWithoutAlign {
  display: flex;
  width: 100%;
  max-height: 100%;
  justify-content: space-between !important;
  margin-bottom: 5px;
}

.meetingTitle {
  max-width: 220px;
}

.spaceMoreText {
  margin-right: auto;
}

.quantityClients {
  margin-bottom: 15px;
  color: #685DA8;
}

.borderCard {
  margin-bottom: 5px;
  margin-top: 5px;
  border-bottom: inset;
}

.cardContent {
  width: 100%;
}

.buttonCardContainer {
  position: absolute;
  bottom: 16px;
  margin-left: -10px;
}

.positionRoom {
  margin-left: auto;
}

.cardRoom {
  width: 94px;
  height: 21px;
  margin-left: 16px;
  border-radius: 4px;
  font-family: Graviola, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background:  rgb(234, 85, 120);
} 

.spanBlock{
  display: block;
}

.textCenter{
  text-align: center;
}
