@import '../../assets/styles/Colors';

.rbc-event-continues-later {
  min-height: 38px !important;
}

.titleText {
  font-family: Graviola;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  color: #685da8;
}
.remainingConsultationContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 55px;
}
.remainingConsultationWrapper {
  min-width: 169px;
  h1 {
    display: flex;
    align-items: center;
    font-family: Graviola;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: $mainColor100;
    margin: 0;

    li {
      width: 8px;
      height: 8px;
      margin-right: 8px;
      border-radius: 4px;
      background-color: $mainColor100;
      list-style-type: none;
    }
  }

  p {
    font-family: Graviola;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #737373;
    margin-top: 3px;
    margin: 0;
  }
}

.enfermagem {
  h1 {
    color: #33B6AE;
    li {
      background-color: #33B6AE;
    }
  }
}

.edfisico {
  h1 {
    color: #FF9431;
    li {
      background-color: #FF9431;
    }
  }
}

.psicologia {
  h1 {
    color: #ea5578;
    li {
      background-color: #ea5578;
    }
  }
}

.nutricionista {
  h1 {
    color: #e86e84;
    li {
      background-color: #e86e84;
    }
  }
}
