.partnerNewContainer {
  width: 100%;
  max-width: 1152px;
  margin: 0 20px;
  margin-top: -32px;
}

.paperContainer {
	margin-top: 0px !important;
	justify-content: center;
	padding-top: 74px;
	padding-bottom: 40px;
}