.pagination {
  margin-top: 32px;
  display: flex;
  list-style: none;
  outline: none;
  justify-content: center;
  font-family: Graviola;
  color: #CBC3F7;
}
.pagination > .active > a{
  color: #685DA8;
}

.pagination > li > a{
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  color: #685DA8;
}


.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset
}

.pagination > .next{
  color: #737373 !important;
}

.pagination > .previous{
  color: #737373 !important;
}

.spaceLeft{
  margin-left: 12px;
}

.iconSpaceLeft{
  margin-left: 4px;
}

.warningPlaceholder {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.alert {
  margin-right: 8px;
}