//Special colors

//Main color derivations
$mainColor100: #3E2782; 
$mainColor: #685DA8;
$mainColor200: #CBC3F7;

//Main color contrast derivations
$mainColorContrast100: #D64E0F; 
$mainColorContrast: #F57A3D;
$mainColorContrast200: #FFC375;

//Text color contrast derivations
$textColorPrimary: #737373; 
$textColorSecondary: #B3B3B3;
$textColorDisabled: #E0E0E0;

//Main color background
$mainColorBackground: #FAFAFA;

$basicWhite: #FFFFFF;

//Palletes
@function get-color($component,$type){
    @return map-get(map-get($palettes,$component),$type); 
}


$palettes:(
    mainColor:(
        enabled:$mainColor !important,
        hover: $mainColor !important,
        focused: $mainColor100 !important,
        selected:$mainColor100 !important,
        disabled:transparent !important,
        outlined: $mainColorBackground !important,
        text: $mainColorBackground !important,
        focusedBorder:$mainColor !important,
        linkFocused: $mainColor200 !important,
    ),
    textColorPrimary: (
        enabled:$textColorPrimary !important,
        hover: $textColorPrimary !important,
        focused: $textColorPrimary !important,
        selected:$textColorPrimary !important,
        disabled:transparent !important,
        outlined: $basicWhite !important,
        text: $textColorPrimary !important,
    ),
    mainColorSecondary:(
        enabled:$mainColorContrast !important,
        hover: $mainColorContrast !important,
        focused: $mainColorContrast100 !important,
        selected:$mainColorContrast100 !important,
        disabled:transparent !important,
        outlined: $basicWhite !important,
        text: $mainColorBackground !important,
        focusedBorder:$mainColorContrast !important,
    ),
    textColor: (
        primary:$textColorPrimary !important,
        secondary:$textColorSecondary !important,
        disable:$textColorDisabled !important,
    )
);