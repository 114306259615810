@import '../../../../assets/styles/Style';

.primaryButtonHeaderContainer {
  .CTAPrimaryButton {
    .MuiButton-label {
      .textStyle {
        font-size: 14px !important;
        line-height: 28px !important;
      }
    }
  }
}

.MuiButton-fullWidth {
  margin: 0 !important;
}

.clientName {
  @extend %h4Style;
}