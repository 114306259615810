@import '../../assets/styles/Colors';

.tabContainer {
  background-color: rgba(104, 93, 168, 0.05);
}

.tabLabelWrapper {
  display: flex;
  flex-direction: row;
}

.tabTitle {
  font-family: GraviolaSoft;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: $mainColor;
  margin-left: 16px;
}
