.modal-pdf{
	display: flex;
	align-items: center;
	justify-content: center;
	::-webkit-scrollbar {
		width: 10px;
	}
	::-webkit-scrollbar-track {
		background: #f1f1f1;
	}
	::-webkit-scrollbar-thumb {
		background: #888;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
	&:focus{
		outline: none;
	}
	.paper-modal{
		background-color: white;
		overflow: auto;
		max-height: calc(100% - 100px);
		min-height: calc(100% - 100px);
		padding:20px;
		&:focus{
			outline: none;
		}
	}
	.icon-container{
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
	}
	.haux {
	
		&__container {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 10px 0;
			padding: 10px;
	
			&__load {
				margin-top: 1em;
				color: white;
			}
	
			&__document {
	
				.react-pdf {
					&__Document {
						display: flex;
						flex-direction: column;
						align-items: center;
					}
	
					&__Page {
						max-width: calc(100% - 2em);
						box-shadow: 0 0 2px rgba(0, 0, 0, .2);
						margin: 1em;
	
						canvas {
							max-width: 100%;
							height: auto !important;
						}
					}
	
					&__message {
						padding: 20px;
						color: white;
					}
				}
			}
		}
	}
}
	