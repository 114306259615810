.filterDiv {
  display: flex;
  padding: 16px;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  & input {
      width: 320px;
      padding-left: 16px;
  }
}