@import '../../assets/styles/Colors';

.CTAPrimaryButton{
  margin: 10px !important;
  background-color: get-color(mainColor,enabled);
  color: get-color(mainColor,text);
  border:1px solid get-color(mainColor, enabled);
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: none !important;

  &:hover{
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  }
  &:focus{
    border: 1px solid get-color(mainColor,focusedBorder);
    box-sizing: border-box;
    border-radius: 4px;
  }
  &:disabled{
    background-color: get-color(textColor,disable);
    border: none !important;
  }
}

.CTASecondaryButton{
  margin: 10px !important;
  background-color: get-color(mainColor,outlined);
  color: get-color(mainColor,enabled);
  border:1px solid get-color(mainColor, enabled);
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: none !important;
  &:hover{
    color: get-color(mainColor,selected);
    border: 1px solid get-color(mainColor,selected);
    box-sizing: border-box;
    border-radius: 4px;
  }
  &:focus{
    box-sizing: border-box;
    border-radius: 4px;
    background: rgba(104, 93, 168, 0.05);
  }
  &:disabled{
    background-color: get-color(textColorPrimary,disabled);
    border: 1px solid #E0E0E0 !important;;
    color: get-color(textColor,disable);
  }
};

.CTATertiaryButton{
  background-color: get-color(mainColor,outlined);
  color: get-color(mainColor,enabled);
  border-radius: 4px;
  box-shadow: none !important;
  &:focus{
    box-sizing: border-box;
    border-radius: 4px;
  }
  &:disabled{
    background-color: transparent;
    border: none !important;;
    color: get-color(textColor,disable);
  }
}


.CTAQuaternaryButton{
  background-color: get-color(mainColor,outlined);
  color: get-color(mainColorSecondary,enabled);;
  border-radius: 4px;
  box-shadow: none !important;
  &:focus{
    box-sizing: border-box;
    border-radius: 4px;
  }
  &:disabled{
    background-color: transparent;
    border: none !important;;
    color: get-color(textColor,disable);
  }
}


.destructivePrimaryButton{
  margin: 10px !important;
  background-color: get-color(mainColorSecondary,enabled);
  color: get-color(mainColorSecondary,text);
  border:1px solid get-color(mainColorSecondary, enabled);
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: none !important;
  &:hover{
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  }
  &:focus{
    border: 1px solid get-color(mainColorSecondary,focusedBorder);
    box-sizing: border-box;
    border-radius: 4px;
  }
  &:disabled{
    background-color: get-color(textColor,disable);
    border: none !important;;
  }
}

.destructiveSecondaryButton{
  margin: 10px !important;
  background-color: get-color(mainColorSecondary,outlined);
  color: get-color(mainColorSecondary,enabled);
  border:1px solid get-color(mainColorSecondary, enabled);
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: none !important;
  &:hover{
    color: get-color(mainColorSecondary,selected);
    border: 1px solid get-color(mainColorSecondary,selected);
    box-sizing: border-box;
    border-radius: 4px;
  }
  &:focus{
    box-sizing: border-box;
    border-radius: 4px;
    background: rgba(104, 93, 168, 0.05);
  }
  &:disabled{
    background-color: transparent;
    border: 1px solid #E0E0E0 !important;
    .MuiButton-label {
      color: get-color(textColor,disable);
    }
  }
};

.destructiveTertiaryButton{
  margin: 10px !important;
  background-color: get-color(mainColorSecondary,outlined);
  color: get-color(mainColorSecondary,enabled);
  border-radius: 4px;
  box-shadow: none !important;
  &:focus{
    box-sizing: border-box;
    border-radius: 4px;
  }
  &:disabled{
    background-color: get-color(textColor,disable);
    border: none !important;;
    color: get-color(textColor,disable) !important;;
  }
}

.linkButton{
  border-radius: 0px !important;
  box-shadow: none !important;
  text-transform: none !important;
  color: white !important;
  &:focus{
    border-bottom: 3px solid get-color(mainColor,linkFocused);
    color: get-color(mainColor,linkFocused);
  }
}

.linkClicked{
  border-radius: 0px !important;
  box-shadow: none !important;
  text-transform: none !important;
  color: white !important;
  border-bottom: 3px solid get-color(mainColor,linkFocused);
  color: get-color(mainColor,linkFocused);
}

.tabButton{
  border-radius: 0px !important;
  box-shadow: none !important;
  text-transform: none !important;
  color: #737373 !important;
  &:focus{
    border-bottom: 3px solid get-color(mainColor,enabled);
    color: get-color(mainColor,enabled);
  }
}

.tabClicked{
  border-radius: 0px !important;
  box-shadow: none !important;
  text-transform: none !important;
  color: #737373 !important;
  border-bottom: 3px solid get-color(mainColor,enabled);
  color: get-color(mainColor,enabled);
}

.tabClickedWhite{
  border-radius: 0px !important;
  box-shadow: none !important;
  text-transform: none !important;
  color: #FFFFFF !important;
  color: #FFFFFF;
}

.textStyle{
  font-size: 16px;
  font-family: GraviolaSoft;
}

.imgStyle{
  margin-right: 8,
}

.CTAOutlinedPrimaryButton{
  margin: 10px !important;
  background-color: white;
  color: $mainColor !important;
  border:1px solid $mainColor !important;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: none !important;

  &:hover{
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  }
  &:focus{
    border: 1px solid get-color(mainColor,focusedBorder);
    box-sizing: border-box;
    border-radius: 4px;
  }
  &:disabled{
    border: 1px solid get-color(textColor,disable);
    color: $textColorPrimary !important;
    background-color: get-color(textColor,disable);
  }
}

.CTAOutlinedSecondaryButton{
  margin: 10px !important;
  background-color: white;
  color: $mainColorContrast100 !important;
  border:1px solid $mainColorContrast100 !important;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: none !important;

  &:hover{
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  }
  &:focus{
    border: 1px solid $mainColorContrast100;
    box-sizing: border-box;
    border-radius: 4px;
  }
  &:disabled{
    border: 1px solid get-color(textColor,disable);
    color: $textColorPrimary !important;
    background-color: get-color(textColor,disable);
  }
}

.CTALinkPrimaryButton{
  margin: 10px !important;
  background-color: white;
  color: $mainColor !important;
  border:0px solid $mainColor !important;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: none !important;

  &:hover{
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  }
  &:focus{
    border: 0px solid get-color(mainColor,focusedBorder);
    box-sizing: border-box;
    border-radius: 4px;
  }
}

.snackbarButton {
  background-color: transparent !important;
  border: 1px solid white !important;
  border-radius: 4px;
  padding: 4px 6px;
  margin: 10px !important;
  box-sizing: border-box;
  box-shadow: none !important;

  & .MuiButton-label {
    font-family: Graviola, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: white;
  }
}
