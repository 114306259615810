@import 'node_modules/react-modal-video/scss/modal-video.scss';

@font-face {
  font-family: 'Graviola';
  src: local('Graviola'), url(https://hauxassets.s3-sa-east-1.amazonaws.com/fonts/Graviola+Soft+Regular.otf) format('truetype');
}

@font-face {
  font-family: 'GraviolaSoft';
  src: local('Graviola'), url(https://hauxassets.s3-sa-east-1.amazonaws.com/fonts/Graviola+Soft+Medium.otf) format('truetype');
}

@font-face {
  font-family: 'GraviolaSoftItalic';
  src: local('Graviola'), url(https://hauxassets.s3-sa-east-1.amazonaws.com/fonts/Graviola+Soft+Medium+Italic.otf) format('truetype');
}

body {
  font-family: Graviola, sans-serif;
  margin: 0;
  height: 100%;
}
