@import '../../assets/styles/Colors';

.paperContainer{
  justify-content: center;
}

.clientNameDashboard{
  font-family: GraviolaSoft;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 37px;
  color: #685DA8;
}

.clientStatus {
  width: 94px;
  height: 21px;
  margin-left: 16px;
  border-radius: 4px;
  font-family: Graviola;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    background: #4ED25D;
  }

  &.inactive {
    background: #B3B3B3;
  }
} 

.infoContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 16px;
}

.programContainer{
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding-right: 16px;
}

.line{
  width: 1px;
  height: 100%;
  background-color: #b3b3b3;
}

.rowLine{
  width: 100%;
  height: 1px;
  background-color: #b3b3b3;
}

.isColumnStyle{
  justify-content: flex-start;
}

.isRowAlign{
  align-items: center;
}

.healthContainer{
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.healthItem{
  padding-bottom: 16px;
  padding-top: 16px;
}

.consultTitle{
  margin-bottom: 8px;
  margin-left: 8px;
}

.basicInfoText{
  margin-left: 8px;
  text-align: center;
}

.basicInfoRow{
  flex-direction: row;
}
.titleModalSchedule{
  font-family: Graviola;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  margin-top: 30px;
  color: get-color(mainColor, enabled);
}  

.titleDataModal{
  font-family: Graviola;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: get-color(textColorPrimary, enabled);
}

.additionalInfoModalTitle {
  @extend .titleDataModal;
  color: black !important;
  font-size: bolder;
  text-transform: uppercase;
  margin-top: 16px;
}

.MuiGrid-justify-xs-center{
  margin-bottom: 8px !important;
}

.consultsHistory{
  display: flex;
  flex-direction: column;
}

.historySpaces{
  margin-top: 30px;
  margin-left: 16px;

}

.consultsHistoryList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .consultsHistoryListButton + .consultsHistoryListButton {
    margin-left: 8px;
  }
}

.consultItem{
  padding-bottom: 16px;
}

.dateContainer{
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  justify-content: space-between;
  align-items: baseline;
}

.dateText{
  font-family: Graviola;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #B3B3B3;
}

.imcTitle{
  font-family: Graviola;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #685DA8;
}

.progressContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dateRow{
  display: flex;
  flex-direction: column;
}

.healthOfClient{
  display: flex;
  flex-direction: column;
}

.svgButton {
  background-color: transparent;
  padding: 0;
  margin: 0;
  display: flex;
  border: none;
  height: 24px;
  width: 24px;
  outline: 0;
  
  :hover {
    cursor: pointer;
  }
}

.extraEventsSvg {
  width: 20px;
  height: 20px;
  padding: 4px;
}

.clientInfoContainer{
  display: flex;
  flex-direction: row;
}

.stykuMeasure {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.stykuGallery {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 300px;
}

.imageStyku {
  flex: 1;
  width: 20%;
  object-fit: contain;
  margin: 20px;
  transition: all 0.5s ease;

  &:hover {
    opacity: 0.7;
    transform: scale(1.2)
  }
}

.react-images__view-wrapper, .react-images__view-image{
  max-height: 100vh !important;
}
