.financesContainer{
  overflow-x: hidden;
}

.typesRegisterContainer{
  margin-left: 20px;
}

.typeContainer{
  display: flex;
  align-items: center;
  flex-direction: row;

  div {
    min-width: 400px;
    display: block;
    margin-left: 0px;
  }
}

.alignTitle{
  margin-top: 13px;
  margin-right: 15px;
  min-width: 100px;
}

.summaryTitleContainer{
  justify-content: space-between;
  align-items: flex-end;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.itensSummaryContainer{
  display: grid;
  margin-top: 8px;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}

.styleMother{
  display: flex;
  justify-content: flex-end;
  margin-left: 60px;
}

.activeSwitch{
  display: flex;
  justify-content: center;
}

.line{
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: #E0E0E0;
  min-width: 50vw;
  margin-bottom: 55px;
  margin-top: 30px;
}

.spaceItens{
  margin-bottom: 100px;
  margin-top: 10px;
}
