@import '../../assets/styles/Style';

.sidebarLogo {
  width: 100%;
  margin-top: 26px;
  padding: 24px 0px;
}

.sidebarLabel {
  @extend %h6NormalStyle;
  font-size: 14px;
  margin-left: 8px;
  color: #685da8;
  text-transform: uppercase;
}

.Mui-selected {
  .sidebarLabel {
    color: #f57a3d;
  }

  .sidebarIcon {
    path {
      fill: #f57a3d;
    }
  }

  .sidebarLogoIcon {
    path {
      fill: transparent;
      stroke: #f57a3d;
      + path {
        fill: #f57a3d;
      }
    }
  }
}