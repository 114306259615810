.contentSpan {
  border-width: 2px;
  border-color: #2C88D9;
  color: #2C88D9;
  border-style: solid;
  border-radius: 20px;
  padding: 0 15px;
  margin-right: 5px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Dica {
  border-color: #E9843B;
  color: #E9843B;
}

.Desafio {
  border-color: #D3455B;
  color: #D3455B;
}

.actionButton {
  height: 24px;
  width: 24px;
  cursor: pointer;
  + .actionButton {
    margin-left: 16px;
  }
}

.titleButton {
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  :hover {
    cursor: pointer;
    color: #685da8;
  }
}

.statusLabel {
  margin-left: 56px;
}