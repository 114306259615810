

.progressContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  padding-top: 8px;
}

.labelColor{
  color: #685DA8;
  text-align: center;
  position: absolute;
  margin-top: 28px;
  min-width: 150px;
}

.progressCircle{
  display: flex;
  justify-content: center;
  width: 12px;
  height: 12px;
  background-color: #E0E0E0;
  border-radius: 12px;
  z-index: 1;
}

.selectedCircle{
  width: 24px;
  height: 24px;
  background-color: #685DA8;
}

.progressLine{
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #E0E0E0;
}
