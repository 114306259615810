
.planContainer{ 
  display: flex;
  flex-direction: column;
  min-height: 240px;
  padding: 8px 16px;

  .priceContainer {
    padding: 0px 10px;
  }
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      margin-bottom: 10px;
    }
  
    span {
      text-align: center;
    }
  }
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex: 2;

  .list {
    flex-direction: column;
    min-height: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-end !important;
    align-items: flex-end !important;
    margin-bottom: -10px;
  }
}

.minIconContainer {
  display: flex !important;
  flex-direction: row !important;

  img {
    margin: 5px;
  }
}

.cartItemContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 50px;

  img {
    margin-right: 20px;
  }

  .planTitle {
    width: 130px;
    text-align: left;
  }
  
  .quantityLabel {
    width: 20px;
    text-align: center;
  }

  .priceLabel {
    width: 100px;
    text-align: left;
  }
}



