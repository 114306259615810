.MuiPaginationItem-root {
  font-family: GraviolaSoft !important;
}

.container {
  position: relative;
  width: 100%;
}

.paginationContainer {
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}