
@import '../../assets/styles/Colors';

.homeContainer{
  background-color: get-color(mainColor,enabled);
  height: 150px;
  width: 100%;
};

.routeText{
  font-size: 16px;
  color: white;
  font-family: Graviola;
}

.logoStyle{
  margin-top: 48px;
  margin-left: 107px;
}

.searchText{
  font-family: Graviola;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  color: #685DA8;
}

.userProfession{
  font-family: Graviola;
  color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
}

.listItemContainer{
  border-radius: 4px;
  background-color: #FFFFFF !important;
  margin-top: 3px !important;
  &:hover{
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    .listItemStyle{
      color: #685DA8;
    }
  }
  &:focus{
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    .listItemStyle{
      color: #685DA8;
    }
  }
}

.listItemStyle{
  font-family: GraviolaSoft;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #737373;
}

.noClientsText{
  font-family: GraviolaSoft;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: get-color(textColor, primary);
}