@import '../../../../assets//styles/Style';

.itemContainer {
  display: flex;
  position: relative;
  width: 138px;
  justify-content: center;
  align-items: center;
  cursor: grab;
}

.scroll-menu-arrow {
  :hover {
    cursor: pointer;
    path {
      fill: #3e2782;
    }
  }
}

.itemLine {
  flex-direction: row;
  background-color: #e0e0e0;
  height: 2px;
  width: 100%;
  position: absolute;
  top: 36%;
  z-index: 0;
}

.innerItemContainer {
  position: relative;
  z-index: 1;
  flex-direction: column;
  display: flex;
  text-align: center;
}

.iconTimeline {
  width: 24px;
  height: 24px;
  padding: 8px;
  background-color: #ffffff;
  align-self: center;
}

.itemParagraph {
  @extend %paragraph;
  text-align: center;
}

.itemTextPlaceHolder {
  @extend %description;
  color: transparent;
}

.arrowButton {
  margin-bottom: 24px;
}

.scroll-menu-arrow--disabled {
  opacity: 0;
}
