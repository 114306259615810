.radioContainer{
  display: flex;
}

.questionSpace{
  margin-top: 8px;
}

.questionContainer{
  margin-top: 32px;
  margin-bottom: 32px;
}

.radioInputContainer{
  margin-top: 32px;
}

.radioInputItem{
  display: flex;
  align-items: center;
}

.rowSpace{
  margin-right: 8px;
}

.rowItems{
  display: flex;
}

.frequencyContainer{
  display: flex;
  align-items: center;
  padding-bottom: 32px;
}

.checkboxItems{
  width: 100%;
}

.restTimeContainer{
  margin-top: 32px;
}

.bottomSpace{
  margin-bottom: 32px;
}

.containerItems{
  display: flex;
  flex-direction: column;
}

.alcoholicContainer{
  padding-left: 8px;
  margin-top: -32px;
}
