@import './Colors';

%paragraph {
  font-family: Graviola, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #737373;
  text-transform: none;
}

%description {
  font-family: Graviola, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #b3b3b3;
}

%h6NormalStyle {
  font-family: Graviola;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #737373;
}

%h5Style {
  font-family: Graviola, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  color: #737373;
}

%h3Style {
  font-family: GraviolaSoft, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 37px;
  color: #737373;
}
 
%h4Style {
  font-family: Graviola, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  color: #737373;
}