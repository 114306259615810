.container {
  margin-top: -24px;
}

.paperContainer{
  margin-top: 16px !important;
  justify-content: center;
  padding: 48px 84px 48px 84px;
}

.charactersText{
  margin-top: 8px;
}

.hintText{
  margin-top: 9px;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: rgba(0, 0, 0, 0.38);
}

.contentTitle{
  margin-bottom: 24px;
}

.descriptionImage{
  font-weight: 500;
}

.clientsTextSpace{
  margin-left: 18px;
}