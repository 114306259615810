.gridContainer{
  justify-content: center;
  max-height: 0;
  position: fixed;
  bottom: 40px;
}

.alertStyle{
  font-family: Graviola !important;
  font-size: 16px !important;
  width: 100%;
  align-items: center;
}
