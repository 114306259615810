.fontSmall {
  font-size: 18px;
}

.bigFont {
  font-size: 18px;
}

.displayButton {
  display: flex;
}

.marginLeftButton {
  margin-left: auto;
}

.gridContainer{
  justify-content: center;
  margin-top: 40px !important;
  height: 100% !important;
  padding-bottom: 40px;
}

.checkboxGrid{
  columns: 2 auto;
}

.marginTp{
  margin-top: 48px !important;
}
