.plansResume {
  border-width: 1px;
  border-style: solid;
  border-color: #E0E0E0;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 24px;
  min-width: 50vw;
}

.resumeCartItem {
  display: flex;
  justify-content: space-between;
}

.resumeCartIdentifier {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .resumeQuantity {
    margin-right: 20px;
    margin-top: 2px;
  }
}

.resumeDescription {
  max-width: 30vw;
  margin-bottom: 30px;
}

.paymentArea {
  display: flex;
  justify-content: space-between;
  padding-left: 24px;
  margin-bottom: 40px;

  .discountLabel {
    height: 48px;
    display: flex;
    align-items: center;
  }
}

.valuesArea {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.discount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  .inputContainer {
    width: 80px;
    padding: 0px;
  }

  .parcelInput {
    width: 32px;
    text-align: right;
  }

  .parcelText {
    margin-left: 16px;
    width: 91px;
    justify-content: space-between;
  }
}
