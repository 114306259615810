.searchContainer {
  margin-top: -40px;
  border-radius: 0 !important;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  padding: 5px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.cartContainer {
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.cartInfoContainer {
  display: flex;
  width: 50%;
  margin-left: 50px;
}

.cart {
  flex: 2;
  display: flex;
  flex-direction: column;
  margin-top: 45px;
}

.emptyCart {
  flex: 2;
  border-width: 2px;
  border-color: #E0E0E0;
  border-style: dashed;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -15px;
  margin-left: -50px;
  margin-top: 24px;

  span {
    color: #E0E0E0;
  }
}

.priceContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: -90px;

  .totalContainer {
    margin-right: 162px;
    margin-bottom: 15px;
  }

  .h5Style, .h6StylePurple {
    margin-left: 10px;
  }
  
  .priceLabel {
    display: flex;
    width: 198px;
  }
}
