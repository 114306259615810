.planNewContainer {
  position: relative;
  max-width: 1156px;
  z-index: 2;
  top: -32px;
  .paperContainer {
    padding-bottom: 74px;
    margin-bottom: 15px;
  }
}
