@import '../../assets/styles/Colors';

.newClientText{
  font-family: Graviola;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  color: get-color(mainColor, enabled);
}

.formGridContainer{
  justify-content: center;
}

.paperContainer{
  margin-top: 0px !important;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.paperTitle{
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.iconStyle{
  margin-right: 16px;
}

.inputStyle{
  margin-bottom: 40px !important;
}

.biologicalSexTitle{
  margin-right: 16px;
}

.biologicalSexGrid{
  text-align: start;
}

.buttonsContainer{
  margin-top: 32px !important;
  margin-bottom: 48px !important;
  flex-direction: row-reverse;
}

.saveButton{
  text-align: end;
}

.marginTp{
  margin-top: 32px !important;
}

.registerHeader{
  display: flex;
  justify-content: space-between;
}

.switchClientColor{
  color: #F57A3D;
}
