@import '../../assets/styles/Style';


.paperTitleIcon {
  margin-right: 16px;
}

.borderControl + .borderControl {
  border-top-width: 1px;
  border-top-color: #e0e0e0;
  border-top-style: solid;
}

.nutrition {
  color: #e86e84;
}

.physicalEducation {
  color: #ff9431;
}

.nursing {
  color: #33b6ae;
}

.hourText {
  @extend %h6NormalStyle;
  color: #685da8;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #685da8;
  padding-bottom: 1px;
}

.nameButton {
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  :hover {
    cursor: pointer;
    color: #685da8;
  }
}

.MuiDropzoneArea-root {
  border-width: 2px !important;
  min-height: 150px !important;
}

.MuiDropzoneArea-text {
  font-family: GraviolaSoft !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 15px;
  color: $textColorSecondary;
  margin-left: 16px;
}

.MuiDropzoneArea-icon {
  color: $textColorSecondary !important;
}

.imageListContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
}

.imageItemContainer {
  position: relative;
  margin-right: 30px;

  .MuiButtonBase-root {
    position: absolute;
    top: -20px;
    right:-20px;
    z-index: 1000;
  }
}

.imageItem {
  height: 150px;
}