
@import '../../assets/styles/Colors';
@import '../../assets/styles/Style';

.routeText{
  font-size: 16px;
  color: white;
  font-family: Graviola;
}

.userProfession{
  font-family: Graviola;
  color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: end;
}

.logoutButton {
  padding: 0;
  background-color: transparent;
  border: none;

  :hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  div {
    padding: 6px 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;

    :hover {
      background-color: transparent;
    }
  }

  span {
    @extend %paragraph;
    color: white;
    padding-left: 8px;
  }
}

.pageNameText {
  @extend %h5Style;
  color: white;
}

.pageSubtitleText {
  @extend %paragraph;
  color: white;
}

.breadcrumbs {
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  margin-top: 12px;
  
  :hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  div {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 4px 0;
    padding-right: 8px;
    
    span {
      @extend %paragraph;
      color: #cbc3f7;
    }
  
    path {
      fill: #cbc3f7;
    }

    :hover {
      background-color: transparent;
    }
  }
  
}