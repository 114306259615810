@import '../../assets/styles/Colors';

.clientName{
  font-family: Graviola;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  color: get-color(textColorPrimary, enabled);
}

.gridContainer{
  margin-top: 30px !important;
}

.paperContainer{
  margin-top: 16px !important;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.iconStyle{
  margin-right: 16px;
}

.buttonsContainer{
  margin-top: 32px !important;
  margin-bottom: 48px !important;
}

.saveButton{
  text-align: end;
}

.buttonBack{
  text-align: initial;
}