.trainingContainer {
  padding: 0 16px;
  margin-top: -50px;
  max-width: 1152px;
}

.guideTitle {
  text-align: center;
  width: 200px;
}

.trainingGuides {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 32px);
  height: 100px;
  padding: 0 16px;
  margin-bottom: 16px;
  align-items: center;
  background-color: white;
}

.guidesWrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 400px;
}

.videoGalleryButton {
  flex: 1;
}

.trainingItem {
  margin-bottom: 16px;
}

.trainingFooter {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  div {
    margin-right: -12px;
  }
}

.emptyStateContainer {
  background-color: white;
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > button {
    padding: 0;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
  }

  > * > iframe {
    margin-right: 16px;
    margin-bottom: 16px;
    border: none;
    pointer-events: none;
  }
}