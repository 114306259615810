.modal-presentation{
	display: flex;
	align-items: center;
	margin:20px 150px;
	justify-content: center;
	&:focus{
		outline: none;
	}
	.paper-modal{
		overflow: hidden;
		width:100%;
		height:100%;
		&:focus{
			outline: none;
		}
	}
	.icon-container{
		position: absolute;
		top:5px;
		right: 5px;
	}
	.haux {
		&__container {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 5px 0;
			padding: 5px;
	
			&__load {
				margin-top: 1em;
				color: white;
			}
	
			&__document {
	
				.react-pdf {
					&__Document {
						display: flex;
						flex-direction: column;
						align-items: center;
						margin-top: 30px;
					}
	
					&__Page {
						max-width: calc(100% - 2em);
						box-shadow: 0 0 2px rgba(0, 0, 0, .2);
						margin: 1em;
	
						canvas {
							max-width: 100%;
							height: auto !important;
						}
					}
	
					&__message {
						padding: 20px;
						color: white;
					}
				}
			}
		}
	}
	.react-pdf__Document {
		position: relative;
	  
		&:hover {
		  	.page-controls {
				opacity: 1;
		  	}
		}
	}
	  
	  .page-controls {
		position: absolute;
		bottom: 5%;
		left: 50%;
		background: white;
		opacity: 0;
		transform: translateX(-50%);
		transition: opacity ease-in-out 0.2s;
	  
		span {
		  font: inherit;
		  font-size: .8em;
		  padding: 0 .5em;
		}
	  
		button {
		  width: 44px;
		  height: 44px;
		  background: white;
		  border: 0;
		  font: inherit;
		  font-size: .8em;
	  
		  &:enabled {
			&:hover {
			  cursor: pointer;
			}
	  
			&:hover, &:focus {
			  background-color: #e6e6e6;
			}
		  }
	  
		  &:first-child {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		  }
	  
		  &:last-child {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		  }
		}
	}
		
}
	
